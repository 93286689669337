<template>
  <div :class="$style.homework">
    <p :class="$style.homework_subtitle">Домашнее задание</p>
    <div :class="$style.homework_wrapper">
      <div>
        <div :class="$style.homework_flex">
          <img src="@/assets/icons/homework-lesson.svg" alt="homework" />
          <span
            :class="[
              $style.homework_text,
              { [$style.homework_text_active]: order.homework }
            ]"
            >{{ orderHomeworkStatusName }}</span
          >
        </div>
        <div v-if="order.homework" :class="$style.homework_flex">
          <p :class="$style.homework_flex_subtitle">Срок сдачи:</p>
          <img src="@/assets/icons/calendar-lesson.svg" alt="date" /><span
            :class="$style.homework_flex_text"
            >{{ homeworkDate }}</span
          >
          <img src="@/assets/icons/time-lesson.svg" alt="time" /><span
            :class="$style.homework_flex_text"
            >{{ homeworkTime }}</span
          >
        </div>
      </div>
      <transition name="fade">
        <custom-button
          v-show="order.homework"
          :class="$style.homework_button"
          type="quaternary"
          @click="openHomeworkModal"
        >
          Смотреть
        </custom-button>
      </transition>
    </div>
  </div>
</template>

<script>
import Moment from 'moment'
import CustomButton from '@/basic/CustomButton'

const MSK = 3

export default {
  components: {
    CustomButton
  },
  props: {
    order: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    orderHomeworkStatusName() {
      return this.order?.homework?.status?.name ?? 'без задания'
    },
    homeworkDate() {
      return this.order.homework?.deadlineAt
        ? Moment(this.order.homework?.deadlineAt).format('DD.MM.YYYY')
        : null
    },
    homeworkTime() {
      const currentTimezoneOffset = new Date().getTimezoneOffset() / 60
      const offset = MSK + currentTimezoneOffset ?? 0
      const datetime = this.order.homework?.deadlineAt
        ? Moment(this.order.homework?.deadlineAt).add(offset, 'hours')
        : null
      return datetime ? `${datetime.format('HH:mm')}` : null
    }
  },
  methods: {
    openHomeworkModal() {
      this.$store.dispatch('openModal', ['Homework'])
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';

.homework {
  width: 100%;
  max-width: 40rem;
  min-height: 5rem;
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 0.625rem;
  background: $smoky-white;
  &_subtitle {
    margin: 0 0 1rem;
    font-size: 0.875rem;
    font-weight: 500;
  }
  &_text {
    font-size: 0.875rem;
    font-weight: 500;
    color: $silver;
    &_active {
      color: $dark-greenish-yellow-green;
    }
  }
  &_flex {
    display: flex;
    align-items: center;
    &:first-child {
      margin: 0 0 1rem;
    }
    &_subtitle {
      margin: 0 0.5rem 0 0;
      font-size: 0.875rem;
      font-weight: 400;
      color: $pearl-light-gray;
    }
    &_text {
      margin: 0 0.5rem 0 0;
      font-size: 0.688rem;
      color: $dark-greenish-yellow-green;
    }
    img {
      margin: 0 0.5rem 0 0;
    }
  }
  &_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &_button {
    padding: 1rem !important;
    border-radius: 0.313rem !important;
    text-transform: none !important;
  }
}
</style>